<template>
  <div class="mange-cv row">
    <div class="mange-cv-left one row" v-if="resumeObj">
      <img :src="resumeObj.avatar_url" class="mange-user-avatar" alt="" />
      <div class="cv-left-center one">
        <div class="user-heard row-center">
          <div class="text-main" v-if="resumeObj.privates.protect_name == 0">
            {{ resumeObj.name }}
          </div>
          <div class="text-main" v-else>{{ resumeObj.name.slice(0, 1) }}{{resumeObj.gender.value==1 ? '先生' : '女士'}}</div>

          <div class="text-blue font-normal flex-center">
            {{ type == 1 ? "平台推送" : "自主投递" }}
          </div>
          <div>刚刚活跃</div>
        </div>
        <div class="text-main user-detail font-normal row-center">
          <span>{{ resumeObj.gender.text }}</span>
          <div>|</div>
          <span>{{ resumeObj.other_info.age }}岁</span>
          <div>|</div>
          <span>工作{{ resumeObj.other_info.work_year }}年</span>
          <div>|</div>
          <span>{{ resumeObj.detail.highest_education }}</span>
          <div>|</div>
          <span>{{ resumeObj.other_info.job_status }}</span>
        </div>
        <div class="text-mian font-lighter">个人优势</div>
        <div class="text-main font-normal user-advantage row-center">
          <div>{{ resumeObj.detail.advantage }}</div>
        </div>
        <div class="text-mian font-lighter">求职期望</div>
        <div
          class="text-main font-normal user-advantage row-center"
          v-if="resumeObj.job_intention.length > 0"
        >
          <div class="row-center">
            <span>{{ resumeObj.job_intention[0].one_position }}</span>
            <div class="user-advantage-border">|</div>
          </div>
          <div class="row-center">
            <span
              >{{ resumeObj.job_intention[0].salary_min }}-{{
                resumeObj.job_intention[0].salary_max
              }}</span
            >
            <div class="user-advantage-border">|</div>
          </div>
          <div class="row-center">
            <span>{{ resumeObj.job_intention[0].position_name }}</span>
            <div class="user-advantage-border">|</div>
          </div>
          <div class="row-center">
            <span>{{ resumeObj.job_intention[0].province }}</span>
          </div>
        </div>
        <div class="text-mian font-lighter">工作经历</div>
        <div v-for="(item, index) in resumeObj.work" :key="index">
          <div class="job-item">
            <div class="row-center job-center-item text-main font-normal">
              <div>{{ item.job_times }}</div>
              <div>{{ item.company_name }}</div>
              <div>{{ item.work_name }}</div>
            </div>
          </div>
          <div class="job-center-text row text-main">
            <div class="center-text-left">内容：</div>
            <div class="job-center-text-item font-normal">
              <div v-for="(itemOne, indexOne) in item.splitAdd" :key="indexOne">
                {{ itemOne }}
              </div>
            </div>
          </div>
        </div>
        <div class="text-mian font-lighter">教育经历</div>
        <div class="educate text-main font-normal" v-if="resumeObj.school.length > 0">
          <div class="row educate-item">
            <div>{{ resumeObj.school[0].school_times }}</div>
            <div>{{ resumeObj.school[0].education }}</div>
            <!-- <div>{{resumeObj.school[0].school_times}}</div> -->
            <div>{{ resumeObj.school[0].school_name }}</div>
            <div>{{ resumeObj.school[0].major }}</div>
          </div>
        </div>
        <div class="job-center-text row text-main">
          <div class="center-text-left">在校经历：</div>
          <div
            class="job-center-text-item font-normal"
            v-for="(item, index) in experience_one"
            :key="index"
          >
            <div>{{ item }}</div>
          </div>
        </div>
        <div class="text-mian font-lighter">资格证书</div>
        <div
          class="row-center text-main font-normal certificate"
          v-if="resumeObj.certificate"
        >
          <div v-for="(item, index) in resumeObj.certificate.certificates" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="mange-cv-right">
      <div class="row-end right-img">
        <img src="../../../assets/glxt/25.png" alt="" class="pointer" @click="navTo" />
      </div>
      <div class="text-main font-normal">沟通职位：{{ position }}</div>
      <div class="right-border-cire space-between">
        <div class="cire"></div>
        <div class="border-cire"></div>
        <div class="cire"></div>
        <div class="border-cire"></div>
        <div class="cire"></div>
      </div>
      <div class="space-between font-normal text-main">
        <div>平台推荐</div>
        <div>您发起聊天</div>
        <div>已约面</div>
      </div>
      <div class="right-border"></div>
      <div
        class="text-white font-normal font-weight-bolder bg-blue flex-center pointer right-button"
        @click="sendClick"
      >
        继续聊天
      </div>
      <div class="space-between button-center font-normal">
        <div class="text-white flex-center pointer" @click="interviewClick">约面试</div>
        <div
          class="text-blue flex-center pointer"
          :class="resumeObj.privates.protect_phone == 1 ? 'protect_phone' : ''"
          @click="phoneClick"
          v-if="resumeObj"
        >
          电话沟通
        </div>
        <div class="flex-center pointer" @click="delectClick">不合适</div>
      </div>
      <div class="img-right space-between font-normal">
        <div class="direction pointer" @click="likeClick">
          <img
            src="../../../assets/forget/9.png"
            class="img-two"
            alt=""
            v-if="is_collection == 1"
          />
          <img src="../../../assets/glxt/22.png" class="img-two" alt="" v-else />
          <div :class="is_collection == 1 ? 'lickClass' : ''">收藏</div>
        </div>
        <div class="direction pointer" @click="feedbackClick">
          <img src="../../../assets/glxt/23.png" class="img-three" alt="" />
          <div>举报</div>
        </div>
        <div class="direction pointer" @click="saveClick">
          <img src="../../../assets/glxt/24.png" class="img-four" alt="" />
          <div>存至本地</div>
        </div>
      </div>
    </div>
    <!-- 面试管理 -->
    <mask-model
      ref="maskModel"
      width="482px"
      sureContent="发送"
      :interview="false"
      @submitClick="submitClick"
      title="发送面试邀请"
    >
      <div class="update-eidt">
        <div class="row-center eift-item">
          <div class="text-main font-normal">面试职位：</div>
          <div class="div-select">
            <el-select
              v-model="interObj.position_id"
              @change="posiChange"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionsTwo"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-center eift-item-one">
          <div class="text-main font-normal">面试时间：</div>
          <div class="row select-item">
            <div class="select-item-div select-item-div-one">
              <el-date-picker
                v-model="timeOne"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
            <div class="select-item-div">
              <el-time-picker
                v-model="timetwo"
                :picker-options="{
                  selectableRange: '8:30:00 - 18:30:00',
                }"
                format="HH:mm"
                value-format="HH:mm"
                placeholder="任意时间点"
              >
              </el-time-picker>
            </div>
          </div>
        </div>
        <div class="row-center eift-item">
          <div class="text-main font-normal">面试地址：</div>
          <div class="div-select">
            <el-input placeholder="请输入详细地址" v-model="interObj.address" clearable>
            </el-input>
          </div>
        </div>
        <div class="row-center eift-item">
          <div class="text-main font-normal">联系人姓名：</div>
          <div class="div-select div-select-one">
            <el-input placeholder="请输入联系人姓名" v-model="interObj.hr_name" clearable>
            </el-input>
          </div>
        </div>
        <div class="row-center eift-item">
          <div class="text-main font-normal">联系方式：</div>
          <div class="div-select">
            <el-input
              placeholder="请输入联系方式"
              v-model="interObj.hr_phone"
              clearable
            >
            </el-input>
          </div>
        </div>
        <div class="row-center eift-item">
          <div class="text-main font-normal">备注事项：</div>
          <div class="div-select">
            <el-input
              type="textarea"
              placeholder="请输入备注事项，最多140字"
              v-model="interObj.remark"
              maxlength="140"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
    </mask-model>
    <!-- 手机号码弹窗 -->
    <mask-model
      ref="maskPhone"
      :imgShow="true"
      title="联系电话"
      width="482px"
      :btnCancel="false"
    >
      <div class="privacy-phone text-main font-lighter" v-if="resumeObj">
        {{ resumeObj.phone ? resumeObj.phone : "暂无联系方式" }}
      </div>
    </mask-model>
  </div>
</template>

<script>
import TIM from "tim-js-sdk";
import api from "../../../api/user";
import entApi from "../../../api/enterprises";
import { mapState } from "vuex";
import maskModel from "../../../components/mask.vue";
export default {
  components: {
    maskModel,
  },
  computed: {
    // 2. 解构状态作为计算属性
    ...mapState(["userDetail", "entDetail"]),
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      likeShow: false,
      user_id: "",
      resumeObj: null,
      experience_one: [],
      position: "",
      type: 1,
      position_id: "",
      is_collection: 0,
      // 邀约面试后端所需的数据
      interObj: {
        user_id: "", //用户id
        enterprise_id: "", //企业id
        ent_name: "", //企业名称
        position_id: "", //职位id
        position_name: "", //职位名称
        province_id: "", //省份id,
        province: "", //省名称
        city_id: "", //市id
        city: "", //市名称
        address: "", //详细地址
        interview_time: "", //面试时间
        hr_name: "",
        hr_phone: "",
        remark: "",
      },
      timeOne: "",
      timetwo: "",
      optionsTwo: [],
    };
  },

  created() {
    if (this.$route.query.id) {
      this.user_id = this.$route.query.id;
      this.position = this.$route.query.position;
      this.type = this.$route.query.type;
      this.position_id = this.$route.query.position_id;
      this.getResume(this.$route.query.id);
      this.isCollectUser(this.$route.query.id);
    }
    this.entPosiList();
  },

  mounted() {},

  methods: {
    feedbackClick(){
      this.$emit('feedbackClick')
    },
    saveClick(){
        this.$confirm("是否将该用户的简历保存?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    setLinkStatue() {
      entApi
        .setLineStatue({
          position_id: this.position_id,
          link_status: 2,
          user_id: this.user_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$util.msg(res.msg);
          }
        });
    },
    // 忽略
    delectClick() {
      this.$confirm("确定设置为不合适, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setLinkStatue();
        })
    },
    phoneClick() {
      if (this.resumeObj.privates.protect_phone == 1) {
        this.$util.msg("对方已开启手机号码保护", "info");
        return;
      }
      this.$refs.maskPhone.show();
    },
    interviewClick() {
      this.$refs.maskModel.show();
    },
    // 获取企业发布的职位
    entPosiList() {
      let entDetail = JSON.parse(localStorage.getItem("entDetail"));
      let id = entDetail.id;
      entApi.simpleList({ enterprise_id: id }).then((res) => {
        if (res.code == 200) {
          let optionsTwo = res.data.map((item) => {
            return {
              value: item.id,
              label: item.position_name,
            };
          });
          this.optionsTwo = optionsTwo;
         
        }
      });
    },
    posiChange(e) {
      let optionActive = this.optionsTwo.filter((item) => {
        return item.value == e;
      });
      this.interObj.position_name = optionActive[0].label;
    },
    yzgz() {
      if (this.interObj.position_id == "") {
        this.$util.msg("请选择面试职位", "warning");
        return false;
      } else if (this.timeOne == "") {
        this.$util.msg("请选择面试日期", "warning");
        return false;
      } else if (this.timetwo == "") {
        this.$util.msg("请选择面试时间", "warning");
        return false;
      } else if (this.interObj.hr_name == "") {
        this.$util.msg("请填写联系人", "warning");
        return false;
      } else if (this.interObj.hr_phone == "") {
        this.$util.msg("请填写联系方式", "warning");
        return false;
      } else if (this.interObj.address == "") {
        this.$util.msg("请填写面试地址", "warning");
        return false;
      } else {
        return true;
      }
    },
    // 提交面试邀请
    submitClick() {
      if (!this.yzgz()) return;
      this.interObj.user_id = this.user_id;
      this.interObj.enterprise_id = this.entDetail.id;
      this.interObj.ent_name = this.entDetail.ent_name;
      this.interObj.province_id = this.entDetail.province_id;
      this.interObj.province = this.entDetail.province;
      this.interObj.city = this.entDetail.city;
      this.interObj.city_id = this.entDetail.city_id;
      this.interObj.interview_time = this.timeOne + " " + this.timetwo;



       let query = {
        user_id: this.user_id, //用户id
        user: this.resumeObj.user_name, //用户名称
        head: this.resumeObj.avatar_url, //用户头像
        ent_name: this.entDetail.ent_name, //企业名称
        ent_id: this.entDetail.id, //企业id
        ent_logo: this.entDetail.logo_url, //企业logo
        contacts: this.userDetail.user_name, //企业hr名字
        jopId: this.position_id, //工作id
        jop: this.position, //职位名称
        customStatus: 1, //沟通状态
        id: this.userDetail.id + "_2", //hrid
      };
       let interviewObj = {
        position: this.position,
        positionValue: this.position_id,
        time: this.interObj.interview_time,
        address: this.interObj.address,
        name: this.interObj.hr_name,
        phone: this.interObj.hr_phone,
        remark: this.interObj.remark,
      };
      let message = this.$tim.createCustomMessage({
        to: this.user_id.toString(),
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: "inte",
          description: JSON.stringify(interviewObj),
          extension: JSON.stringify(query),
        },
      });
      // 2. 发送消息
      let promise = this.$tim.sendMessage(message);
      promise
        .then((imResponse) => {
          // console.log('发送成功',imResponse)
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });

      api.sendInterView(this.interObj).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg);
          this.$refs.maskModel.hide();
          this.$util.objClear(this.interObj);
          this.timeOne = "";
          this.timetwo = "";
        }
      });
    },
    isCollectUser(id) {
      api.isCollectUser({ user_id: id }).then((res) => {
        this.is_collection = res.data.is_collection;
      });
    },
    sendClick() {
      let query = {
        user_id: this.resumeObj.id, //用户id
        user: this.resumeObj.user_name, //用户名称
        head: this.resumeObj.avatar_url, //用户头像
        ent_name: this.entDetail.ent_name, //企业名称
        ent_id: this.entDetail.id, //企业id
        ent_logo: this.entDetail.logo_url, //企业logo
        contacts: this.userDetail.user_name, //企业hr名字
        jopId: this.position_id, //工作id
        jop: this.position, //职位名称
        customStatus: 1, //沟通状态
        id: this.userDetail.id + "_2", //hrid
      };
      let message = this.$tim.createCustomMessage({
        to: this.resumeObj.id.toString(),
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: "text",
          description: "打扰了,我司正在招聘该职位的人才，是否方便沟通一下呢",
          extension: JSON.stringify(query),
        },
      });
      // 2. 发送消息
      let promise = this.$tim.sendMessage(message);
      promise
        .then((imResponse) => {
          this.$router.push({
            path: "/layout/interactive",
            query: { id: this.resumeObj.id },
          });
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    },
    getResume(id) {
      api.getResume({ user_id: id }).then((res) => {
        if (res.code == 200) {
          if (res.data.work.length > 0) {
            res.data.work.forEach((item) => {
              if (item.to_hide == 1) {
                item.to_hide = true;
              } else {
                item.to_hide = false;
              }
              if (item.work_content) {
                item.splitAdd = item.work_content.split("\n");
              }
            });
          }
          if (res.data.school.length > 0) {
            if (res.data.school[0].experience) {
              this.experience_one = res.data.school[0].experience.split("\n");
            }
          }
          this.resumeObj = res.data;
        }
      });
    },
    likeClick() {
      entApi
        .collectionPerson({
          user_id: this.user_id,
          position_id: this.position_id,
          position_name: this.position,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$util.msg(res.msg);
            this.isCollectUser(this.user_id);
          }
        });
    },
    navTo() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.mange-cv {
  width: 100%;
  padding: 0 9.1875rem 1.25rem 9.1875rem;
  .mange-cv-left {
    width: 100%;
    background: #ffffff;
    padding: 32px 0 32px 31px;
    .mange-user-avatar {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      margin-right: 1.5rem;
    }
    .cv-left-center {
      width: 100%;
      .user-heard {
        font-weight: 300;
        color: #999999;
        font-size: 13px;
        margin-bottom: 12px;
        div:first-child {
          font-size: 21px;
        }
        div:nth-child(2) {
          width: 79px;
          height: 24px;
          border: 1px solid #126bf9;
          border-radius: 4px;
          margin: 0 10px;
        }
      }
      .user-detail {
        margin-bottom: 38px;
        div {
          margin: 0 6px;
        }
      }
      .user-advantage {
        margin: 30px 0 46px 0;
        .user-advantage-border {
          margin: 0 8px;
        }
      }
      .job-item {
        margin: 30px 0;
        .job-center-item {
          margin-bottom: 10px;
          div:first-child {
            min-width: 120px;
          }
          div:nth-child(2) {
            min-width: 180px;
          }
        }
      }
      .job-center-text {
        margin-bottom: 34px;
        .center-text-left {
          font-size: 15px;
          margin-right: 4px;
        }
        .job-center-text-item {
          div {
            line-height: 23px;
          }
        }
      }
      .educate {
        margin: 30px 0;
        .educate-item {
          margin-bottom: 10px;
          div:nth-child(2) {
            margin: 0 10px 0 6px;
          }
          div:nth-child(4) {
            margin: 0 30px 0 18px;
          }
        }
      }
      .certificate {
        margin-top: 30px;
        div {
          margin-right: 10px;
        }
      }
    }
  }
  .mange-cv-right {
    width: 18.25rem;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
    margin-left: 2px;
    padding: 22px 18px 0 25px;
    .right-img {
      padding-right: 4px;
      img {
        width: 17px;
        height: 17px;
      }
    }
    .right-border-cire {
      padding: 0 16px 0 25px;
      margin: 48px 0 11px 0;
      .border-cire {
        width: 82px;
        height: 2px;
        background: #126bf9;
      }
      .cire {
        width: 8px;
        height: 8px;
        background: #126bf9;
        border-radius: 50%;
      }
    }
    .right-border {
      margin: 30px 0 20px 0;
      width: 100%;
      height: 1px;
      background: #eeeeee;
    }
    .right-button {
      width: 100%;
      height: 34px;
      margin-bottom: 10px;
    }
    .button-center {
      margin-bottom: 33px;
      div {
        width: 72px;
        height: 32px;
      }
      div:first-child {
        background: #c5c7cf;
      }
      div:nth-child(2) {
        background: #dae8ff;
      }
      div:last-child {
        border: 1px solid #c5c7cf;
      }
      .protect_phone {
        background: #c5c7cf !important;
        color: #ffffff;
      }
    }
    .img-right {
      color: #9fa3b0;
      .lickClass {
        color: #126bf9;
      }
      .img-two {
        width: 23px;
        height: 22px;
        margin-bottom: 10px;
      }
      .img-three {
        width: 23px;
        height: 21px;
        margin-bottom: 11px;
      }
      .img-four {
        width: 24px;
        height: 20px;
        margin-bottom: 12px;
      }
    }
  }
  .update-eidt {
    width: 100%;
    margin: 30px 0;
    padding-right: 30px;

    .eift-item {
      margin-bottom: 21px;
      .div-select {
        width: 290px;
        margin-left: 20px;
        /deep/.el-input--suffix {
          width: 290px;
        }
        /deep/.el-textarea__inner {
          height: 90px;
        }
      }
      .div-select-one {
        margin-left: 6px;
      }
    }
    .eift-item-one {
      margin-bottom: 21px;
      .select-item {
        margin-left: 20px;
        .select-item-div {
          width: 140px;
          /deep/.el-input {
            width: 140px;
          }
        }
        .select-item-div-one {
          margin-right: 10px;
        }
      }
    }
  }
  .privacy-phone {
    padding: 20px 0 26px 0;
  }
}
</style>
